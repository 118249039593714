<template>
    <div class="app-container">
        <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px">
            <el-form-item label="名称" prop="title">
                <el-input
                        v-model="queryParams.title"
                        clearable
                        size="mini"
                />
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
                <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
            </el-form-item>
        </el-form>
        <el-row :gutter="10" class="mb8">
            <el-col :span="1.5">
                <el-button
                        type="primary"
                        plain
                        size="mini"
                        v-permission="'clue:crux:add'"
                        @click="handleAdd"
                >增加关键字段
                </el-button>
            </el-col>
        </el-row>

        <el-table max-height="660" v-loading="loading" :data="dataList">
            <el-table-column type="selection" width="55" align="center"/>
            <el-table-column label="名称" align="center" prop="title"/>
            <el-table-column label="定义来源" align="center" prop="source">
                <template v-slot="scope">
                    <span v-for="item in sourceList" v-if="scope.row.source === item.value">{{item.label}}</span>
                </template>
            </el-table-column>
            <el-table-column label="类型" align="center" prop="type">
                <template v-slot="scope">
                    <el-tooltip v-for="item in typeList" class="item" effect="dark" :content="item.tip" placement="top"
                                v-if="scope.row.type === item.value">
                        <span>{{ item.label }}</span>
                    </el-tooltip>
                </template>
            </el-table-column>
            <!--            <el-table-column label="状态" align="center" prop="status"/>-->
            <el-table-column label="操作" fixed="right" align="center" class-name="small-padding fixed-width">
                <template v-slot="scope">
                    <el-button
                            size="mini"
                            type="text"
                            v-permission="'clue:crux:update'"
                            @click="handleUpdate(scope.row)"
                    >修改
                    </el-button>
                    <el-button
                            size="mini"
                            type="text"
                            v-permission="'clue:crux:dict'"
                            v-if="scope.row.source === 'EXTRA'"
                            @click="showDict(scope.row)"
                    >查看字典
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <pagination
                v-show="total>0"
                :total="total"
                :page.sync="queryParams.current"
                :limit.sync="queryParams.size"
                @pagination="getList"
        />

        <!-- 添加或修改参数配置对话框 -->
        <el-dialog :title="title" :visible.sync="open" width="80%" append-to-body>
            <el-form ref="form" :model="form" :rules="rules" label-width="120px" size="mini">
                <el-form-item label="名称" prop="title">
                    <el-input v-model="form.title" placeholder="请输入名称"/>
                </el-form-item>
                <el-form-item label="代码" prop="code">
                    <el-input v-model="form.code" placeholder="请输入代码"/>
                </el-form-item>
                <el-form-item label="类型" prop="type">
                    <el-select v-model="form.type">
                        <el-option v-for="item in typeList" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import * as clueCruxApi from "@/api/clue/clueCrux";
import CluePage from "./component/CluePage.vue";
import * as possApi from "@/api/crm/possess";

export default {
    name: "ClueCrux",
    components: {CluePage},
    data() {
        return {
            queryParams: {
                current:1,
                size:10
            },
            form: {
                pondIds: "",
            },
            title: "",
            total: 0,
            open: false,
            rules: {},
            loading: false,
            dataList: [],
            adviserList: [],
            single: false,
            pondList: [],
            expandall: false,
            defaultProps: {
                children: 'children',
                label: 'title'
            },
            typeList: [{"label": "项目", "value": "subject", "tip": "一建、二建、其他"},
                {"label": "所属团队", "value": "BHBIGCFCIH", "tip": "此处项目人员自行填写，后续机会创建外挂中根据成单团队放此值"},
                {"label": "升级机会来源-分类", "value": "BHBIIGGHFE", "tip": "额外字段"},
                {"label": "唯一配置", "value": "possess", "tip": "唯一配置管理"},
                {"label": "特殊分类", "value": "BHBIGCFBGB", "tip": "128试验单、学习时长高筛、1元单。（此字段业务人员自行创建，手工上传）"}],
            sourceList:[
                {"label":"机会字段","value":"SELF"},
                {"label":"额外字段","value":"EXTRA"},
            ],
        };
    },
    created() {
        this.getList();
        this.getDeptTree();
    },
    methods: {
        getDeptTree(id) {
            possApi.findDeptTreeByPoss(id).then(res => {
                this.deptTree = res.data;
            })
        },
        /** 查询参数配置列表 */
        getList() {
            // this.loading = true;
            clueCruxApi.pageClueCrux(this.queryParams).then(response => {
                this.dataList = response.data.records;
                this.total = response.data.total;
                this.loading = false;
            });
        },
        // 取消按钮
        cancel() {
            this.open = false;
            this.reset();
        },
        // 表单重置
        reset() {
            this.form = {
                configId: null,
                configName: null,
                configKey: null,
                configValue: null,
                configType: null,
                createBy: null,
                createTime: null,
                updateBy: null,
                updateTime: null,
                remark: null
            };
            this.datetime = []
            this.queryParams = {
                current: 1,
                size: 10,
                startTime: null,
                endTime: null,
                action: null,
                module: null,
                accountId: null
            }
            this.resetForm("form");
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.current = 1;
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.resetForm("queryForm");
            this.handleQuery();
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map(item => item.configId)
            this.single = selection.length !== 1
            this.multiple = !selection.length
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.reset();
            this.open = true;
            this.title = "添加配置";
        },
        /** 查看字典 */
        showDict(row){
            this.$message.success("该功能二期制作")
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.reset();
            let id = row.id
            clueCruxApi.infoCluePond(id).then(response => {
                this.form = response.data;
                this.open = true;
                this.title = "修改配置";
            });
        },
        /** 提交按钮 */
        submitForm() {
            this.$refs["form"].validate(valid => {
                if (valid) {
                    if (this.form.id != null) {
                        clueCruxApi.updateCluePond(this.form).then(response => {
                            this.msgSuccess("修改成功");
                            this.open = false;
                            this.getList();
                        });
                    } else {
                        clueCruxApi.addCluePond(this.form).then(response => {
                            this.msgSuccess("新增成功");
                            this.open = false;
                            this.getList();
                        });
                    }
                }
            });
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            const ids = row.ids || this.ids;
            this.$confirm('是否确认删除线索池为"' + ids + '"的数据项?', "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(function () {
                return clueCruxApi.deleteCluePond(ids)
            }).then(() => {
                this.getList();
                this.msgSuccess("删除成功");
            })
        }
    }
}
</script>

<style scoped>

</style>